<template>
  <v-container fluid class="content-wrapper">
    <div class="d-flex flex-row justify-start align-center mb-5">
      <h4 class="fw-600">{{ $t("SigninActivity.title") }}</h4>
      <v-btn icon class="ml-3" @click="initAll()" :class="loadingData && 'animate__spining'" :disabled="loadingData">
        <v-icon>mdi-reload</v-icon>
      </v-btn>
    </div>
    <v-row row wrap>
      <v-col cols="12">
        <div class="pa-5 activity-card">
          <div v-if="loadingData" class="form-box">
            <v-skeleton-loader
              type="list-item-three-line, list-item-three-line, list-item-three-line"
            ></v-skeleton-loader>
          </div>
          <div v-else class="form-box">
            <div class="form-group">
              <div class="box-perprice">
                <div class="box-inner per_room active">
                  <table class="table table-roomrate activity-table">
                    <thead class="activity-header">
                      <tr>
                        <th></th>
                        <th>{{ $t("SigninActivity.name") }}</th>
                        <th>{{ $t("SigninActivity.rateprice") }}</th>
                        <th>{{ $t("SigninActivity.price") }}</th>
                        <th class="col-del"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-for="(item, index) in itemsActivity">
                        <tr :key="index">
                          <th width="1%" style="vertical-align: middle !important">
                            <div class="mt-4">{{ index + 1 }}</div>
                          </th>
                          <th width="20%">
                            <v-row class="mx-1">
                              <v-col cols="12" sm="12" md="12" lg="12">
                                <input
                                  :id="`${item._id}_name`"
                                  type="text"
                                  class="form-control"
                                  placeholder
                                  name
                                  v-model="item.name"
                                  disabled
                                />
                              </v-col>
                            </v-row>
                          </th>
                          <th width="17%">
                            <div
                              class="form-group mb-0 mx-1 d-flex flex-row align-center justify-center"
                            >
                              <div
                                class="dropdown select"
                                @click="getfocus(`${item._id}_perprice`)"
                                @mouseleave="mouseOver(`${item._id}_perprice`)"
                              >
                                <a
                                  data-toggle="dropdown"
                                  data-display="static"
                                  :id="`${item._id}_perprice`"
                                >
                                  <v-select
                                    v-model="item.perprice"
                                    :items="itemsPerPrice"
                                    item-text="name"
                                    item-value="value"
                                    label
                                    placeholder
                                    :no-data-text="$t('SignupStep.nodata')"
                                    color="pink"
                                    solo
                                    flat
                                    dense
                                    single-line
                                    hide-details
                                    disabled
                                  >
                                    <template v-slot:item="data">
                                      <v-list-item-content
                                        @mouseenter="getfocus(`${item._id}_perprice`)"
                                        @mouseleave="mouseOver(`${item._id}_perprice`)"
                                      >
                                        <v-list-item-title
                                          v-html="data.item.name"
                                        ></v-list-item-title>
                                      </v-list-item-content>
                                    </template>
                                  </v-select>
                                </a>
                              </div>
                            </div>
                          </th>
                          <th width="52%">
                            <v-row
                              v-if="item.perprice == 'guest'"
                              class="d-flex flex-row align-center justify-center mx-1"
                            >
                              <v-col
                                cols="12"
                                sm="12"
                                md="4"
                                lg="4"
                                v-if="!$vuetify.breakpoint.mobile"
                              />
                              <v-col
                                cols="12"
                                sm="12"
                                md="4"
                                lg="4"
                                class="d-flex flex-column align-center justify-center"
                              >
                                <label class="light label-record">
                                  {{ $t("SigninActivity.adultrate") }}
                                </label>
                                <input
                                  :id="`${item._id}_adult_rate`"
                                  type="number"
                                  min="0"
                                  class="form-control"
                                  placeholder
                                  name
                                  v-model="item.adult_rate"
                                  disabled
                                />
                              </v-col>
                              <v-col
                                cols="12"
                                sm="12"
                                md="4"
                                lg="4"
                                class="d-flex flex-column align-center justify-center"
                              >
                                <label class="light label-record">
                                  {{ $t("SigninActivity.childrate") }}
                                </label>
                                <input
                                  :id="`${item._id}_child_rate`"
                                  type="number"
                                  min="0"
                                  class="form-control"
                                  placeholder
                                  name
                                  v-model="item.child_rate"
                                  disabled
                                />
                              </v-col>
                            </v-row>
                            <v-row
                              v-else
                              class="d-flex flex-row align-center justify-center mx-1"
                            >
                              <v-col
                                cols="12"
                                sm="12"
                                md="4"
                                lg="4"
                                class="d-flex flex-column align-center justify-center"
                              >
                                <label class="light label-record">
                                  {{ $t("SignupStep3.baserate") }}
                                </label>
                                <input
                                  :id="`${item._id}_base_rate`"
                                  type="number"
                                  min="0"
                                  class="form-control"
                                  placeholder
                                  name
                                  v-model="item.base_rate"
                                  disabled
                                />
                              </v-col>
                              <v-col
                                cols="12"
                                sm="12"
                                md="4"
                                lg="4"
                                class="d-flex flex-column align-center justify-center"
                              >
                                <label class="light label-record">{{
                                  $t("SigninActivity.adultrate")
                                }}</label>
                                <input
                                  :id="`${item._id}_extra_bed_adult`"
                                  type="number"
                                  min="0"
                                  class="form-control"
                                  placeholder
                                  name
                                  v-model="item.extra_bed_adult"
                                  disabled
                                />
                              </v-col>
                              <v-col
                                cols="12"
                                sm="12"
                                md="4"
                                lg="4"
                                class="d-flex flex-column align-center justify-center"
                              >
                                <label class="light label-record">{{
                                  $t("SigninActivity.childrate")
                                }}</label>
                                <input
                                  :id="`${item._id}_extra_bed_child`"
                                  type="number"
                                  min="0"
                                  class="form-control"
                                  placeholder
                                  name
                                  v-model="item.extra_bed_child"
                                  disabled
                                />
                              </v-col>
                            </v-row>
                          </th>
                          <th class="col-del" width="10%">
                            <div class="d-flex flex-row align-center mx-1 mb-3">
                              <v-icon color="#ff4961" @click="removeActivity(item, index)"
                                >mdi-minus-circle-outline</v-icon
                              >
                              <v-icon
                                v-if="
                                  index == itemsActivity.length - 1 &&
                                  itemsNewActivity.length == 0
                                "
                                color="success"
                                class="ml-1"
                                @click="addNewLine()"
                                >mdi-plus-circle-outline</v-icon
                              >
                            </div>
                          </th>
                        </tr>
                      </template>
                      <template v-for="(item, index) in itemsNewActivity">
                        <tr :key="index + itemsActivity.length">
                          <th width="1%" style="vertical-align: middle !important">
                            <div class="mt-4">
                              {{ index + 1 + itemsActivity.length }}
                            </div>
                          </th>
                          <th width="20%">
                            <v-row class="mx-1">
                              <v-col cols="12" sm="12" md="12" lg="12">
                                <input
                                  :id="`${item._id}_name`"
                                  type="text"
                                  class="form-control"
                                  placeholder
                                  name
                                  v-model="item.name"
                                  :disabled="item.canremove"
                                />
                              </v-col>
                            </v-row>
                          </th>
                          <th width="17%">
                            <div
                              class="form-group mb-0 mx-1 d-flex flex-row align-center justify-center"
                            >
                              <div
                                class="dropdown select"
                                @click="getfocus(`${item._id}_perprice`)"
                                @mouseleave="mouseOver(`${item._id}_perprice`)"
                              >
                                <a
                                  data-toggle="dropdown"
                                  data-display="static"
                                  :id="`${item._id}_perprice`"
                                >
                                  <v-select
                                    v-model="item.perprice"
                                    :items="itemsPerPrice"
                                    item-text="name"
                                    item-value="value"
                                    label
                                    placeholder
                                    :no-data-text="$t('SignupStep.nodata')"
                                    color="pink"
                                    solo
                                    flat
                                    dense
                                    single-line
                                    hide-details
                                  >
                                    <template v-slot:item="data">
                                      <v-list-item-content
                                        @mouseenter="getfocus(`${item._id}_perprice`)"
                                        @mouseleave="mouseOver(`${item._id}_perprice`)"
                                      >
                                        <v-list-item-title
                                          v-html="data.item.name"
                                        ></v-list-item-title>
                                      </v-list-item-content>
                                    </template>
                                  </v-select>
                                </a>
                              </div>
                            </div>
                          </th>
                          <th width="52%">
                            <v-row
                              v-if="item.perprice == 'guest'"
                              class="d-flex flex-row align-center justify-center mx-1"
                            >
                              <v-col
                                cols="12"
                                sm="12"
                                md="4"
                                lg="4"
                                v-if="!$vuetify.breakpoint.mobile"
                              />
                              <v-col
                                cols="12"
                                sm="12"
                                md="4"
                                lg="4"
                                class="d-flex flex-column align-center justify-center"
                              >
                                <label class="light label-record">
                                  {{ $t("SigninActivity.adultrate") }}
                                </label>
                                <input
                                  :id="`${item._id}_adult_rate`"
                                  type="number"
                                  min="0"
                                  class="form-control"
                                  placeholder
                                  name
                                  v-model="item.adult_rate"
                                />
                              </v-col>
                              <v-col
                                cols="12"
                                sm="12"
                                md="4"
                                lg="4"
                                class="d-flex flex-column align-center justify-center"
                              >
                                <label class="light label-record">
                                  {{ $t("SigninActivity.childrate") }}
                                </label>
                                <input
                                  :id="`${item._id}_child_rate`"
                                  type="number"
                                  min="0"
                                  class="form-control"
                                  placeholder
                                  name
                                  v-model="item.child_rate"
                                />
                              </v-col>
                            </v-row>
                            <v-row
                              v-else
                              class="d-flex flex-row align-center justify-center mx-1"
                            >
                              <v-col
                                cols="12"
                                sm="12"
                                md="4"
                                lg="4"
                                class="d-flex flex-column align-center justify-center"
                              >
                                <label class="light label-record">
                                  {{ $t("SignupStep3.baserate") }}
                                </label>
                                <input
                                  :id="`${item._id}_base_rate`"
                                  type="number"
                                  min="0"
                                  class="form-control"
                                  placeholder
                                  name
                                  v-model="item.base_rate"
                                />
                              </v-col>
                              <v-col
                                cols="12"
                                sm="12"
                                md="4"
                                lg="4"
                                class="d-flex flex-column align-center justify-center"
                              >
                                <label class="light label-record">{{
                                  $t("SigninActivity.adultrate")
                                }}</label>
                                <input
                                  :id="`${item._id}_extra_bed_adult`"
                                  type="number"
                                  min="0"
                                  class="form-control"
                                  placeholder
                                  name
                                  v-model="item.extra_bed_adult"
                                />
                              </v-col>
                              <v-col
                                cols="12"
                                sm="12"
                                md="4"
                                lg="4"
                                class="d-flex flex-column align-center justify-center"
                              >
                                <label class="light label-record">{{
                                  $t("SigninActivity.childrate")
                                }}</label>
                                <input
                                  :id="`${item._id}_extra_bed_child`"
                                  type="number"
                                  min="0"
                                  class="form-control"
                                  placeholder
                                  name
                                  v-model="item.extra_bed_child"
                                />
                              </v-col>
                            </v-row>
                          </th>
                          <th class="col-del" width="10%">
                            <div class="d-flex flex-row align-center mx-1 mb-3">
                              <v-icon color="#ff4961" @click="removeActivity(item, index)"
                                >mdi-minus-circle-outline</v-icon
                              >
                              <v-icon
                                v-if="index == itemsNewActivity.length - 1"
                                color="success"
                                class="ml-1"
                                @click="addNewLine()"
                                >mdi-plus-circle-outline</v-icon
                              >
                            </div>
                          </th>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-btn
      dark
      depressed
      color="#666ee8"
      @click="dialogConfirmSave = true"
      v-if="!loadingData"
      style="min-width: 10.625rem"
      >{{ $t("SigninGeneral.btn_save") }}</v-btn
    >
    <v-dialog v-model="dialogConfirmSave" persistent max-width="360">
      <v-card>
        <v-card-title
          class="text-center"
          style="font-size: 1rem !important; letter-spacing: normal !important"
        >
          {{ $t("Alert.doyouwanttosave") }}
        </v-card-title>
        <v-card-actions class="justify-center align-center pb-5">
          <v-btn
            depressed
            dark
            color="#e83e8c"
            class="pa-3 mr-3"
            @click="saveActivity()"
            >{{ $t("SigninGeneral.btn_save") }}</v-btn
          >
          <v-btn color="pink" class="pa-3" outlined @click="dialogConfirmSave = false">
            {{ $t("SigninGeneral.btn_cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import swal from "sweetalert";
import { EventBus } from "@/EventBus";

export default {
  name: "SigninActivityScreen",
  metaInfo: {
    title: "Activity",
    titleTemplate: "%s | Aigent",
  },
  beforeRouteLeave(to, from, next) {
    var changeValue = this.checkChangeData();

    if (changeValue) {
      const answer = window.confirm(this.$t("Alert.needtogootherroute"));
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else next();
  },
  data: () => ({
    userToken: "",
    propertyID: "",
    propertyData: null,
    itemsDefaultActivity: [],
    itemsOldActivity: [],
    itemsActivity: [],
    itemsNewActivity: [],
    itemsRemoveActivity: [],
    itemsPerPrice: [],
    value_element: "",
    numNewActivity: 0,
    numAddNew: 0,
    tempArrayNew: [],
    dialogConfirmSave: false,
    saveNewActivity: false,
    itemsRatePlanPrice: [],
    itemsPackageUse: [],
    showWarning: false,
    loadingData: false,
  }),
  created() {
    const self = this;
    self.userToken = self.$route.params.userToken;
    self.propertyID = self.$route.params.propertyId;
    self.initAll();
  },
  mounted() {
    const self = this;
    EventBus.$on("refreshRightPhoto", self.initAll);
    self.colorHover();
  },
  watch: {},
  methods: {
    colorHover() {
      const button = document.querySelector(".hover_btn");
      button.addEventListener("mousemove", (e) => {
        const { x, y } = button.getBoundingClientRect();
        button.style.setProperty("--x", e.clientX - x);
        button.style.setProperty("--y", e.clientY - y);
      });
    },
    getfocus(val) {
      var element = document.getElementById(val);
      if (this.value_element === "") {
        element.classList.add("border-pink-focus");
        this.value_element = val;
      } else if (this.value_element) {
        document.getElementById(this.value_element).classList.remove("border-pink-focus");
        element.classList.add("border-pink-focus");
        this.value_element = val;
      }
    },
    mouseOver(val) {
      var element = document.getElementById(val);
      if (this.value_element) {
        document.getElementById(this.value_element).classList.remove("border-pink-focus");
        element.classList.remove("border-pink-focus");
        this.value_element = "";
      }
    },
    initAll() {
      const self = this;
      self.itemsPerPrice = [
        {
          value: "room",
          name: self.$t("SigninActivity.per_voucher"),
        },
        {
          value: "guest",
          name: self.$t("SigninActivity.per_person"),
        },
      ];
      self.initPropertyData();
      self.initRatePlanPrice();
    },
    async initPropertyData() {
      const self = this;
      var temp = null;
      var tempActivity = [];

      self.loadingData = true;

      EventBus.$emit("loadingtillend");
      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API +
            `/property?_id=${self?.propertyID || self?.$route?.params?.propertyId}`,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          temp = res?.data?.result[0] || null;
          // tempActivity =  || [];
          // console.log(temp);
          temp?.activities?.map((el) => {
            if (el?._id && el?.name)
              tempActivity.push({
                _id: el?._id,
                name: el?.name || "",
                perprice: el.per_guest ? "guest" : "room",
                per_room: el.per_room,
                per_guest: el.per_guest,
                base_rate: el?.base_rate || null,
                extra_bed_adult: el?.extra_bed_adult || null,
                extra_bed_child: el?.extra_bed_child || null,
                adult_rate: el?.adult_rate || null,
                child_rate: el?.child_rate || null,
                hoverdel: false,
                canremove: true,
              });
          });
          if (!temp?.activities?.length) self.addNewLine();
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
      } finally {
        EventBus.$emit("endloading");
        self.propertyData = temp;
        tempActivity.sort((a, b) => a?.name?.localeCompare(b?.name) || a?.name - b?.name);
        self.itemsActivity = tempActivity;
        self.itemsDefaultActivity = temp?.activities;
        self.itemsOldActivity = temp?.activities;
        self.itemsNewActivity = [];
        self.addNewLine();
        self.loadingData = false;
      }
    },
    setupActivity(data) {
      const self = this;
      var temp = [];
      if (data?.length) {
        data?.map((el, index) => {
          if (el?._id && el?.name)
            temp.push({
              _id: el?._id,
              name: el?.name || "",
              perprice: el.per_guest ? "guest" : "room",
              per_room: el.per_room,
              per_guest: el.per_guest,
              base_rate: el?.base_rate || null,
              extra_bed_adult: el?.extra_bed_adult || null,
              extra_bed_child: el?.extra_bed_child || null,
              adult_rate: el?.adult_rate || null,
              child_rate: el?.child_rate || null,
              hoverdel: false,
              canremove: true,
            });
          // console.log(temp, index);
          if (temp.length - index == 1) return self.addNewLine();
        });
      }
      self.itemsActivity = temp;
      self.itemsDefaultActivity = data;
      // console.log(data, temp);

      // if (
      //   !self?.itemsActivity?.length ||
      //   self?.itemsActivity[self?.itemsActivity?.length - 1].name
      // )
      //   self.addNewLine();
      // if (data.length == self.itemsActivity.length) self.addNewLine();
    },
    async initRatePlanPrice() {
      const self = this;
      var temp = [];
      var tempPackage = [];

      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API +
            `/property/rate_plan_price?property_id=${
              self?.propertyID || self?.$route?.params?.propertyId
            }`,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          temp = res?.data?.result;
          res?.data?.result?.map((el) => {
            if (el?.package?.length) {
              el?.package?.map((ele) => {
                const findDupPackage = tempPackage?.find(
                  (p) => p._id == ele?.activity?._id
                );
                if (!findDupPackage && ele?.activity?._id)
                  tempPackage.push(ele?.activity?._id);
              });
            }
          });
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
      } finally {
        EventBus.$emit("endloading");
        self.itemsRatePlanPrice = temp;
        self.itemsPackageUse = tempPackage;
      }
    },
    addNewActivity(item) {
      const self = this;
      var obj;
      var word = "";

      var elementName = document.getElementById(`${item._id}_name`);
      var elementAdultRate = document.getElementById(`${item._id}_adult_rate`);
      var elementChildRate = document.getElementById(`${item._id}_child_rate`);
      var elementBaseRate = document.getElementById(`${item._id}_base_rate`);
      var elementExtraAdultRate = document.getElementById(`${item._id}_extra_bed_adult`);
      var elementExtraChildRate = document.getElementById(`${item._id}_extra_bed_child`);

      if (!item.canremove) {
        if (!item?.name) {
          elementName.classList.add("border-pink-focus");
        } else {
          elementName?.classList?.remove("border-pink-focus");
        }
        if (item?.perprice == "guest") {
          if (!item?.adult_rate) {
            elementAdultRate.classList.add("border-pink-focus");
          } else {
            elementAdultRate.classList.remove("border-pink-focus");
          }
          if (!item?.child_rate) {
            elementChildRate.classList.add("border-pink-focus");
          } else {
            elementChildRate.classList.remove("border-pink-focus");
          }
        } else {
          if (!item?.base_rate) {
            elementBaseRate.classList.add("border-pink-focus");
          } else {
            elementBaseRate.classList.remove("border-pink-focus");
          }
          if (!item?.extra_bed_adult) {
            elementExtraAdultRate.classList.add("border-pink-focus");
          } else {
            elementExtraAdultRate.classList.remove("border-pink-focus");
          }
          if (!item?.extra_bed_child) {
            elementExtraChildRate.classList.add("border-pink-focus");
          } else {
            elementExtraChildRate.classList.remove("border-pink-focus");
          }
        }

        if (item?.name && item?.perprice) {
          if (item?.perprice == "guest") {
            if (item?.adult_rate && item?.child_rate) {
              self.numNewActivity++;
              obj = {
                name: item?.name || "",
                per_room: false,
                per_guest: true,
                base_rate: null,
                extra_bed_adult: null,
                extra_bed_child: null,
                adult_rate: item?.adult_rate || 0,
                child_rate: item?.child_rate || 0,
              };
              self.tempArrayNew.push(obj);
            } else if (!item?.adult_rate) {
              word = self.$t("SignupStep3.adultrate");
              self.alertNoData(word);
            } else if (!item?.child_rate) {
              word = self.$t("SignupStep3.childrate");
              self.alertNoData(word);
            } else self.alertNoData();
          } else if (item?.perprice == "room") {
            if (item?.base_rate && item?.extra_bed_adult && item?.extra_bed_child) {
              self.numNewActivity++;
              obj = {
                name: item?.name || "",
                per_room: true,
                per_guest: false,
                base_rate: item?.base_rate || 0,
                extra_bed_adult: item?.extra_bed_adult || 0,
                extra_bed_child: item?.extra_bed_child || 0,
                adult_rate: null,
                child_rate: null,
              };
              self.tempArrayNew.push(obj);
            } else if (!item?.base_rate) {
              word = self.$t("SignupStep3.baserate");
              self.alertNoData(word);
            } else if (!item?.extra_bed_adult) {
              word = `${self.$t("SignupStep3.extrabed")}${self.$t("SignupStep3.adult")}`;
              self.alertNoData(word);
            } else if (!item?.extra_bed_child) {
              word = `${self.$t("SignupStep3.extrabed")}${self.$t("SignupStep3.child")}`;
              self.alertNoData(word);
            } else self.alertNoData();
          }
        } else if (!item?.name) {
          word = `${self.$t("SigninActivity.name")}`;
          self.alertNoData(word);
        } else self.alertNoData();

        if (self.saveNewActivity) {
          // for (let i = 0; i < allnewdata; i++) {
          //   self.confirmAddNewActivity(self.tempArrayNew[i]);
          // }
          // self.itemsNewActivity?.map(item => {
          //   if (
          //     !item.canremove &&
          //     (item?.name ||
          //       (item?.perprice == "guest" &&
          //         (item?.adult_rate || item?.child_rate)) ||
          //       (item?.perprice == "room" &&
          //         (item?.base_rate ||
          //           item?.extra_bed_adult ||
          //           item?.extra_bed_child)))
          //   ) {
          //     const obj =
          //       item?.perprice == "room"
          //         ? {
          //             name: item?.name || "",
          //             per_room: true,
          //             per_guest: false,
          //             base_rate: item?.base_rate || 0,
          //             extra_bed_adult: item?.extra_bed_adult || 0,
          //             extra_bed_child: item?.extra_bed_child || 0,
          //             adult_rate: null,
          //             child_rate: null
          //           }
          //         : {
          //             name: item?.name || "",
          //             per_room: false,
          //             per_guest: true,
          //             base_rate: null,
          //             extra_bed_adult: null,
          //             extra_bed_child: null,
          //             adult_rate: item?.adult_rate || 0,
          //             child_rate: item?.child_rate || 0
          //           };
          //     self.confirmAddNewActivity(obj);
          //   }
          // });
        }
      } else {
        self.addNewLine();
      }
    },
    async confirmAddNewActivity(obj, remainAdd) {
      const self = this;
      var remainAddLine = 0;
      remainAddLine = remainAdd;

      EventBus.$emit("loadingtillend");

      try {
        const res = await self.axios.post(
          process.env.VUE_APP_API + `/property/activity?property_id=${self.propertyID}`,
          obj,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          self.numAddNew += 1;
          remainAddLine -= 1;
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
        swal(
          self.$t("Alert.warn_title"),
          error?.response?.data?.message || self.$t("Alert.tryagain"),
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      } finally {
        if (remainAddLine < 1 && !self.itemsRemoveActivity?.length) {
          EventBus.$emit("endloading");
          swal(
            self.$t("Alert.success_title"),
            self.$t("Alert.save_success"),
            self.$t("Alert.success_label"),
            {
              button: false,
              timer: 3000,
            }
          ).then(() => {
            self.itemsNewActivity = [];
            self.initAll();
          });
        } else if (self.itemsRemoveActivity?.length) {
          self.itemsRemoveActivity?.map((el, index) => {
            self.confirmRemoveLine(el, self.itemsRemoveActivity?.length - index);
          });
        }
      }
    },
    alertNoData(word) {
      const self = this;
      if (word) {
        swal(
          self.$t("Alert.warn_title"),
          `${self.$t("Alert.pleasefill")}${word}`,
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      } else {
        swal(
          self.$t("Alert.warn_title"),
          self.$t("Alert.fillinfo"),
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      }
    },
    addNewLine() {
      const self = this;
      const temp = {
        _id: self?.itemsActivity?.length + self?.itemsNewActivity?.length || 0,
        name: "",
        perprice: "guest",
        per_room: false,
        per_guest: true,
        base_rate: null,
        extra_bed_adult: null,
        extra_bed_child: null,
        adult_rate: null,
        child_rate: null,
        hoverdel: false,
        canremove: false,
      };
      self.itemsNewActivity.push(temp);
      // console.log("new line: ", self.itemsNewActivity);
    },
    async removeActivity(item, index) {
      const self = this;
      var tmpActivity = self.itemsActivity.filter((val) => {
        return val?._id && val?.name;
      });
      // console.log("tmpActivity: ", tmpActivity);
      const activityid = item?._id || "";
      const findUsePackage = self.itemsPackageUse?.find((p) => p == activityid);
      EventBus.$emit("loadingtillend");

      if (item.canremove && !findUsePackage) {
        // try {
        //   const res = await self.axios.delete(
        //     process.env.VUE_APP_API +
        //       `/property/activity?activity_id=${activityid}`,
        //     {
        //       headers: {
        //         Authorization:
        //           self?.userToken ||
        //           localStorage?.getItem("Token") ||
        //           self?.$store?.state?.resSignUpData?.access_token,
        //         "X-Language-Code":
        //           localStorage?.getItem("language") ||
        //           self?.$store?.state?.language
        //       }
        //     }
        //   );
        // } catch (error) {
        //   EventBus.$emit("endloading");
        //   console.log(error?.response?.data?.message || error);
        //   swal(
        //     self.$t("Alert.warn_title"),
        //     error?.response?.data?.message || self.$t("Alert.tryagain"),
        //     self.$t("Alert.warn_label"),
        //     {
        //       button: false,
        //       timer: 3000
        //     }
        //   );
        // } finally {
        //   EventBus.$emit("endloading");
        //   // if (self.itemsDefaultActivity?.length == tmpActivity?.length)
        //   self.initAll();
        // }
        self.itemsRemoveActivity.push(activityid);
        self?.itemsActivity.splice(index, 1);
        EventBus.$emit("endloading");
      } else if (findUsePackage) {
        swal(
          self.$t("Alert.warn_title"),
          self.$t("SigninActivity.alert_cantremove"),
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
        EventBus.$emit("endloading");
        // if (self.itemsDefaultActivity?.length == tmpActivity?.length - 1)
        // self.initAll();
      } else {
        setTimeout(() => {
          self?.itemsNewActivity.splice(index, 1);
          // if (
          //   self.itemsDefaultActivity?.length ==
          //   self.itemsActivity?.length - 1
          // )
          //   self.initAll();
          EventBus.$emit("endloading");
        }, 500);
      }
    },
    async confirmRemoveLine(activityid, remainLine) {
      const self = this;
      var remainRemoveLine = 0;
      remainRemoveLine = remainLine;
      try {
        const res = await self.axios.delete(
          process.env.VUE_APP_API + `/property/activity?activity_id=${activityid}`,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
        if (res?.status == 200) {
          remainRemoveLine -= 1;
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
        swal(
          self.$t("Alert.warn_title"),
          error?.response?.data?.message || self.$t("Alert.tryagain"),
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      } finally {
        EventBus.$emit("endloading");
        if (remainRemoveLine < 1) {
          self.itemsRemoveActivity = [];
          swal(
            self.$t("Alert.success_title"),
            self.$t("Alert.save_success"),
            self.$t("Alert.success_label"),
            {
              button: false,
              timer: 3000,
            }
          ).then(() => self.initAll());
        }
      }
    },
    saveActivity() {
      const self = this;
      self.dialogConfirmSave = false;
      var countnewactivity = 0;
      var tmpNewActivity = [];
      self.numNewActivity = 0;
      self.tempArrayNew = [];
      self.saveNewActivity = false;

      self.saveNewActivity = true;
      self.itemsNewActivity?.map((el) => {
        if (
          !el.canremove &&
          el?.name &&
          ((el?.perprice == "guest" && el?.adult_rate && el?.child_rate) ||
            (el?.perprice == "room" &&
              el?.base_rate &&
              el?.extra_bed_adult &&
              el?.extra_bed_child))
        ) {
          tmpNewActivity.push(el);
        } else if (
          !el.canremove &&
          (el?.name || (el?.perprice == "guest" && (el?.adult_rate || el?.child_rate)))
        )
          self.saveNewActivity = false;
        else if (
          !el.canremove &&
          (el?.name ||
            (el?.perprice == "room" &&
              (el?.base_rate || el?.extra_bed_adult || el?.extra_bed_child)))
        )
          self.saveNewActivity = false;
      });

      countnewactivity = tmpNewActivity?.length;

      if (self?.itemsNewActivity?.length && !self.saveNewActivity) {
        self?.itemsNewActivity?.map((el) => {
          self.addNewActivity(el);
        });
      } else if (tmpNewActivity?.length) {
        tmpNewActivity?.map((item, i) => {
          const obj =
            item?.perprice == "room"
              ? {
                  name: item?.name || "",
                  per_room: true,
                  per_guest: false,
                  base_rate: item?.base_rate || 0,
                  extra_bed_adult: item?.extra_bed_adult || 0,
                  extra_bed_child: item?.extra_bed_child || 0,
                  adult_rate: null,
                  child_rate: null,
                }
              : {
                  name: item?.name || "",
                  per_room: false,
                  per_guest: true,
                  base_rate: null,
                  extra_bed_adult: null,
                  extra_bed_child: null,
                  adult_rate: item?.adult_rate || 0,
                  child_rate: item?.child_rate || 0,
                };
          self.confirmAddNewActivity(obj, countnewactivity - i);
        });
      } else if (self.itemsRemoveActivity?.length && self.saveNewActivity) {
        self.itemsRemoveActivity?.map((el, index) => {
          self.confirmRemoveLine(el, self.itemsRemoveActivity?.length - index);
        });
      } else {
        swal(
          self.$t("Alert.success_title"),
          self.$t("Alert.save_success"),
          self.$t("Alert.success_label"),
          {
            button: false,
            timer: 3000,
          }
        ).then(() => self.initAll());
      }
    },
    checkChangeData() {
      const self = this;
      var checkchangeactivities = false;
      var cantleavethispage = true;

      self.itemsNewActivity?.map((el) => {
        if (
          el?.name ||
          el?.base_rate ||
          el?.extra_bed_adult ||
          el?.extra_bed_child ||
          el?.adult_rate ||
          el?.child_rate
        )
          checkchangeactivities = true;
      });

      if (checkchangeactivities) {
        cantleavethispage = true;
      } else cantleavethispage = false;
      return cantleavethispage;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../styles/bootstrap/global.css";
@import "../../../../styles/signin/setting.css";

.moving-left {
  /* height: 100px; */
  /* position: relative; */
  transition: transform 0.3s ease-in-out;
  transform: translateX(0px);
  /* width: 100px; */
}

.moving-left:hover {
  transform: translateX(-10px);
}

.hover_numberbtn:hover {
  border: solid 1px transparent;
  color: #fff !important;
  background-image: linear-gradient(#f9f9f9, #f9f9f9),
    radial-gradient(circle at top left, #f00, #3020ff);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #fff inset;
}

.btn.btn-addtype:hover {
  background-color: #ea084a !important;
  border-color: #ea084a !important;
}

.btn.gl-bt {
  overflow: hidden;
  border: 0;
  background-color: var(--pink);
  padding: 0 0.625rem;
}

.btn.gl-bt:hover {
  background-color: #652b80;
}

.btn.gl-bt::before {
  top: calc(var(--y, 0) * 1px - 50px);
  left: calc(var(--x, 0) * 1px - 50px);
  --size: 0;
  content: "";
  position: absolute;
  width: var(--size);
  height: var(--size);
  background: radial-gradient(circle closest-side, #e8107f, transparent);
  transform: translate(-50%, -50%);
  transition: width 0.2s ease, height 0.2s ease;
}

.gl-bt:hover:before {
  --size: 350px;
}

.border-pink-focus {
  // background-color: #fff;
  border-color: var(--pink) !important;
}

.v-input__slot {
  border: 1px solid var(--border-gray) !important;
}

.title-green {
  color: var(--green) !important;
}

.title-gray {
  color: var(--gray) !important;
}

.navtop-canclick {
  cursor: pointer !important;
}

.btn[disabled] {
  min-height: 42px !important;
  height: fit-content !important;
}
</style>

<style scoped>
@import "../../../../styles/signin/holiday.css";
@import "../../../../styles/signin/rateplan.css";

.table-roomrate thead th {
  font-size: 0.9rem !important;
  font-weight: bold !important;
}

.table-roomrate tbody th {
  vertical-align: bottom;
}

.table-roomrate .form-control {
  height: 3rem !important;
}

.select.dropdown > a,
.bootstrap-select.dropdown > .dropdown-toggle {
  border-color: #bdbdbd;
}

.activity-header th {
  vertical-align: bottom;
  border-bottom: none !important;
  /* border-bottom: 2px solid #e3ebf3; */
  /* border-top: 1px solid #e3ebf3; */
}

.activity-table {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
  color: #6b6f82;
  font-family: "Quicksand", Georgia, "Times New Roman", Times, serif;
}

.activity-table tbody tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
  font-family: "Quicksand", Georgia, "Times New Roman", Times, serif;
  /* border-bottom: 1px solid #e3ebf3; */
}

.activity-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 0.35rem;
  margin-bottom: 1.875rem;
  border: none;
  box-shadow: 0 2px 18px 1px rgb(49 53 72 / 10%);
  font-family: "Quicksand", Georgia, "Times New Roman", Times, serif;
}

.label-record {
  font-size: 14px;
  font-family: "Quicksand", Georgia, "Times New Roman", Times, serif;
}
</style>
